@tailwind components;
@layer components {
  .tab {
    @apply list-none flex items-center self-stretch;
  }
  .modalBackdrop {
    @apply fixed h-screen w-screen overflow-hidden top-0;
  }
  .modal {
    @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 absolute positionCenter shadow-md  bg-white;
  }
  .linkButton {
    @apply appearance-none p-0;
  }
  .contentArea {
    @apply mx-2 md:mx-20 lg:mx-42;
  }
  .positionCenter {
    @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }
  .flexCenter {
    @apply flex items-center justify-center;
  }
  .navLink.active {
    @apply text-teal-normal font-semibold;
  }
  .pagerButton {
    @apply w-8 h-8 active:bg-gray-light hover:bg-gray-light_active;
  }
  ul.menu {
    @apply bg-white border-gray-5 border-solid border  shadow-lg rounded-sm;
  }
  ul.menu .item {
    @apply flex items-center py-2 px-3 hover:bg-teal-light_hover w-full text-left;
  }
  .plainTable {
    @apply w-full;
  }
  .plainTable th {
    @apply font-semibold text-gray-400 p-2;
  }
  .plainTable tbody tr {
    @apply border border-gray-light border-collapse;
  }
  .plainTable td {
    @apply p-2 text-center;
  }
  .plainTable tbody tr:hover {
    background: rgba(230, 246, 247, 0.8);
    cursor: pointer;
  }
  .visible-on-hover {
    display: none;
  }
  .show-items-on-hover:hover .visible-on-hover {
    display: initial;
  }

  .max-width-60 {
    max-width: 60px;
  }
  .max-width-80 {
    max-width: 130px;
  }
}
