@tailwind components;
@layer components {
  .DashBoardMetricCard {
    @apply flex items-center flex-1 bg-white p-6;
    height: 124px;
  }
}

.dashboardContent {
  background-color: rgba(236, 236, 236, 0.25);
}

.DashBoardMetricCard .metricValue {
  font-size: 50px;
  font-weight: 700;
  line-height: 100%;
}
.DashBoardMetricCard .metricName {
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  color: #828282;
  text-transform: uppercase;
}
