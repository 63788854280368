@tailwind components;
@layer components {
  input[type='text'],
  input[type='number'],
  input[type='password'] {
    @apply border border-gray-light_active py-2 px-3 
    leading-tight focus:outline-none focus:border-teal-light_active;
  }
  input[type='text'].error,
  input[type='number'].error,
  input[type='password'].error,
  textarea.error,
  .selectContainer.error {
    border-color: #eb5757;
  }
  input[type='text'].noBorder,
  input[type='password'].noBorder {
    @apply pl-0 border-none;
  }
  input[type='text'].narrow {
    @apply p-1;
  }
  input[type='text'].noBorder.error {
    background-color: #eb5757;
  }

  input[type='checkbox'] {
    @apply appearance-none border border-gray-light_active cursor-pointer
      leading-tight focus:outline-none focus:border-teal-normal
      checked:bg-teal-normal
      checked:bg-[url('assets/icons/checkIcon.svg')] bg-no-repeat bg-center bg-[length:16px_16px];
  }
  .fieldError {
    @apply text-alert-error text-xs;
  }
  label {
    @apply block text-gray-normal;
  }
  textarea {
    @apply p-2 border border-gray-light_active focus:outline-none focus:border-teal-normal;
  }
  button {
    @apply outline-none py-2  flex items-center justify-center;
  }
  button.iconButton {
    @apply p-0;
  }
  button.narrow {
    @apply p-1;
  }
  button.outlined,
  button.outlined_primary {
    @apply border active:border-2;
  }
  button.outlined {
    @apply border-gray-light_active;
  }
  button.outlined_primary {
    @apply border-teal-normal text-teal-normal;
  }
  button.primary {
    @apply bg-teal-normal text-white  border-2 border-transparent active:border-teal-400 focus:border-teal-400;
  }
  button:disabled {
    @apply bg-gray-disabled text-gray-500 opacity-40;
  }
  .selectContainer {
    @apply bg-white border border-gray-light_active py-2 px-3 leading-5 relative cursor-pointer;
  }
  ul.selectMenu li {
    @apply border-b border-b-gray-5 cursor-pointer flex items-start hover:bg-teal-light_hover;
  }
  .dndFIImgTile {
    @apply w-1/3 p-1 h-28 relative;
  }
  .dndFIImgTile img {
    @apply w-full h-full object-cover;
  }

  .radioButton div.outlined.btn {
    @apply border border-gray-300;
  }
  .radioButton input:checked + div.outlined.btn {
    background-color: rgba(230, 246, 247, 0.5);
    border-color: #00a8ab;
  }

  .radioButton div.filled.btn {
    color: #828282;
  }
  .radioButton input:checked + div.filled.btn {
    background-color: #b0e4e5;
    border-color: rgba(230, 246, 247, 0.8);
    @apply text-gray-normal;
  }
  input[type='text'].searchField {
    @apply w-full pr-8;
  }
}
